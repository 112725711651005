import React, {useState, useCallback, useEffect, useMemo} from 'react';
import styled from 'styled-components';
import {useOutlet} from 'reconnect.js';
import {message, Table, Tooltip} from 'antd';
import Filters from './Filters';
import StatusTag from './StatusTag';
import {DownloadOutlined, ExclamationOutlined} from '@ant-design/icons';

const PAGINATION_INIT = {
  current: 1,
  pageSize: 20,
};

const FILE_TYPE = {
  hct_summary: '新竹物流托運總表',
  logistics_print: '物流單列印',
  sf_summary: '順豐物流托運總表',
};

export default function Downloads() {
  const [actions] = useOutlet('actions');
  const [loading, setLoading] = useOutlet('loading');
  const [filters, setFilters] = useState({
    status: 'all',
    type: 'all',
    ...PAGINATION_INIT,
  });
  const [files, setFiles] = useState([]);
  const [total, setTotal] = useState(0);

  const changeFilters = (obj) => setFilters((prev) => ({...prev, ...obj}));

  const getFiles = useCallback(async () => {
    let {current, pageSize, status, type} = filters;

    setLoading(true);

    let params = {
      offset: (current - 1) * pageSize,
      limit: pageSize,
      ordering: '-updated',
      ...(status !== 'all' && {
        status,
      }),
      ...(type !== 'all' && {
        type,
      }),
    };

    try {
      const {results, count} = await actions.fetchLogisticFiles(params);
      setFiles(results);
      setTotal(count);
    } catch (ex) {
      message.warning('取得物流單錯誤');
    }
    setLoading(false);
  }, [filters, actions]);

  const generateErrorMessage = (details) => {
    return (
      <>
        {details
          .filter((item) => item.status === 'failed')
          .map((item, index) => (
            <React.Fragment key={item.id}>
              {index > 0 && <br />}
              {`${item.id} - ${item.error_message}`}
            </React.Fragment>
          ))}
      </>
    );
  };

  useEffect(() => {
    getFiles();
  }, [getFiles]);

  const columns = useMemo(
    () => [
      {
        title: '編號',
        width: 80,
        render: (_, instance) => (
          <span>
            #{instance.id}
            {instance.status !== 'succeeded' && instance.details.length > 0 && (
              <Tooltip
                title={generateErrorMessage(instance.details)}
                arrowPointAtCenter>
                <ExclamationOutlined
                  style={{color: 'red', fontSize: 16, fontWeight: 'bold'}}
                />
              </Tooltip>
            )}
          </span>
        ),
      },
      {
        title: '檔案類型',
        width: 160,
        render: (_, instance) => FILE_TYPE[instance.type],
      },
      {
        title: '建立時間',
        render: (_, instance) => instance.created_at,
      },
      {
        title: '驅動人',
        render: (_, instance) => instance.user.name,
      },
      {
        title: '狀態',
        minWidth: 120,
        render: (_, instance) => <StatusTag instance={instance} />,
      },
      {
        title: '操作',
        render: (_, instance) => (
          <a
            href={instance.url}
            target="_blank"
            rel="noreferrer"
            style={{
              pointerEvents: ['succeeded', 'partial_succeeded'].includes(
                instance.status,
              )
                ? 'auto'
                : 'none',
            }}>
            <DownloadOutlined
              style={{
                fontSize: 20,
                color: ['succeeded', 'partial_succeeded'].includes(
                  instance.status,
                )
                  ? instance.status === 'partial_succeeded'
                    ? '#EEA23E'
                    : 'inherit'
                  : '#d9d9d9',
              }}
            />
          </a>
        ),
      },
    ],
    [],
  );

  return (
    <Wrapper>
      <Filters
        filters={filters}
        changeFilters={changeFilters}
        getFiles={getFiles}
      />
      <Container>
        <Table
          loading={loading}
          columns={columns}
          dataSource={files}
          rowKey={(item) => item.id}
          pagination={{
            pageSize: filters.pageSize,
            current: filters.current,
            total,
          }}
          onChange={(pagination) =>
            changeFilters({
              pageSize: pagination.pageSize,
              current: pagination.current,
            })
          }
        />
      </Container>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background-color: #f2f2f2;
  padding: 20px;
`;

const Container = styled.div`
  background-color: #fff;
  padding: 16px;
  border-radius: 8px;
  && .ant-pagination {
    margin-bottom: 0;
  }
`;
