import React from 'react';
import styled from 'styled-components';

const FILE_STATUS = {
  init: '等待中',
  processing: '處理中',
  partial_succeeded: '部分成功',
  succeeded: '成功',
  failed: '失敗',
};

const StatusTag = ({instance}) => {
  const {status} = instance;
  const getTagColor = (status) => {
    switch (status) {
      case 'init':
        return {color: '#6B7280', backgroundColor: '#F9FAFA'};
      case 'processing':
        return {color: '#EEA23E', backgroundColor: '#FFF8EB'};
      case 'partial_succeeded':
        return {color: '#EEA23E', backgroundColor: '#FFF8EB'};
      case 'pickup':
        return {color: '#227FE9', backgroundColor: '#DBEFFE'};
      case 'succeeded':
        return {color: '#41AE49', backgroundColor: '#F0FAF0'};
      case 'failed':
        return {color: '#F04438', backgroundColor: '#FEF6F6'};
      default:
        return {color: '#6B7280', backgroundColor: '#F9FAFA'};
    }
  };

  const {color, backgroundColor} = getTagColor(status);

  return (
    <Tag color={color} backgroundColor={backgroundColor}>
      <span className="point"></span>
      {FILE_STATUS[status]}
    </Tag>
  );
};

const Tag = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
  font-size: 14px;
  border-radius: 100px;
  color: ${({color}) => color};
  background-color: ${({backgroundColor}) => backgroundColor};
  max-width: 100px;
  & .point {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: ${({color}) => color};
    margin-right: 4px;
  }
`;

export default StatusTag;
