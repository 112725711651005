import React from 'react';
import styled from 'styled-components';
import {Select, Input, Button} from 'antd';
import {Refresh} from '@styled-icons/boxicons-regular/Refresh';
import getOptions from '../../Utils/getOptions';

const FILE_TYPE = {
  hct_summary: '新竹物流托運總表',
  logistics_print: '物流單列印',
  sf_summary: '順豐物流托運總表',
};

const FILE_STATUS = {
  init: '等待中',
  processing: '處理中',
  succeeded: '成功',
  failed: '失敗',
};

const TYPE_OPTIONS = getOptions(FILE_TYPE);
const STATUS_OPTIONS = getOptions(FILE_STATUS);
const allStatus = {label: '全部', value: 'all'};
const PAGINATION_INIT = {
  current: 1,
  pageSize: 20,
};

export default function Filters({filters, changeFilters, getFiles}) {
  return (
    <Container>
      <InputGroup>
        <p>檔案類型</p>
        <StyledSelect
          placeholder="選擇檔案類型"
          defaultValue={filters?.type}
          onChange={(nextValue) =>
            changeFilters({type: nextValue, ...PAGINATION_INIT})
          }>
          {TYPE_OPTIONS.map((option, idx) => (
            <Select.Option key={idx} value={option.value}>
              {option.label}
            </Select.Option>
          ))}
        </StyledSelect>
      </InputGroup>
      <InputGroup>
        <p>檔案狀態</p>
        <StyledSelect
          placeholder="選擇檔案狀態"
          defaultValue={filters?.status}
          onChange={(nextValue) =>
            changeFilters({status: nextValue, ...PAGINATION_INIT})
          }>
          {STATUS_OPTIONS.map((option, idx) => (
            <Select.Option key={idx} value={option.value}>
              {option.label}
            </Select.Option>
          ))}
        </StyledSelect>
      </InputGroup>
      <StyledButton
        onClick={getFiles}
        icon={<Refresh color="#1890ff" size={24} style={{marginRight: 8}} />}>
        刷新
      </StyledButton>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  align-items: end;
  flex-wrap: wrap;
  padding: 16px;
  column-gap: 16px;
  background-color: #fff;
  border-radius: 8px;
  margin-bottom: 16px;
`;

const InputGroup = styled.div`
  flex: 1;
  p {
    margin-bottom: 8px;
    font-size: 14px;
    font-weight: 500;
    color: #9b9b9b;
  }
`;

const StyledInput = styled(Input.Search)`
  height: 48px;
  &&& {
    .ant-input-affix-wrapper {
      padding: 12px;
    }
    .ant-btn-icon-only {
      height: 48px;
      width: 48px;
    }
    .anticon-search {
      font-size: 20px;
    }
  }
`;

const StyledSelect = styled(Select)`
  width: 100%;
  &&& {
    .ant-select-selector {
      padding: 12px 16px;
      height: 48px;
    }
    .ant-select-selection-item {
      line-height: 24px;
    }
    .ant-select-arrow {
      right: 16px;
    }
  }
`;

const StyledButton = styled(Button)`
  && {
    display: flex;
    align-items: center;
    width: 92px;
    height: 48px;
    border-radius: 10px;
    border: 2px solid #227fe9;
    background-color: transparent;
    color: #227fe9;
    font-size: 14px;
    font-weight: 500;
  }
`;
